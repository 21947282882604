/**
* @author 贝才[beica1@outook.com]
* @date 2021/2/26
* @description
*   In.vue of WeTrade
*/
<template>
<PageWithHeader>
  <template #title>
    <t path="trade_12">Deposit</t>
  </template>
  <template #right>
    <TheRefreshIcon @click="refreshBalance" />
    <!--<Icon name="refresh" :class="{refresh: refreshClick}" @click="refreshBalance" />-->
  </template>
  <div class="px-16 t-center">
    <t as="p" path="deposit_2" class="mt-24 mb-4 c-title f-sm">Free margin</t>
    <span class="f-bold" style="font-size: 31px">
      <TheAsset #="{freeMargin: free}">
        <money :v="free" #="{symbol, value}">
          <span class="f-nm">{{symbol}}</span>{{value}}
        </money>
      </TheAsset>
    </span>
  </div>
  <div class="wedge" style="height: 40px"></div>
  <div class="px-16">
    <router-view v-if="ready" :channels="channels" />
    <div class="mt-32 mb-20 df-between">
      <Pic src="/img/in/bbb@2x.png" width="80" />
      <Pic src="/img/in/dds@2x.png" width="80" />
      <Pic src="/img/in/trustwave@2x.png" width="80" />
    </div>
  </div>
</PageWithHeader>
</template>

<script lang="ts">
import { refreshAccount } from '@/modules/app/app'
import TheRefreshIcon from '@/modules/in/components/TheRefreshIcon.vue'
import { DepositChannel, readChannelList } from '@/modules/in/in.api'
import TheAsset from '@/modules/user/components/TheAsset.vue'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import { AccountType } from '@/state'
import { changeAccountType } from '@/state/accountType'
import Pic from 'common/Pic.vue'
import { defineComponent, shallowRef } from 'vue'

export default defineComponent({
  components: { TheRefreshIcon, Pic, TheAsset, PageWithHeader },
  setup () {
    const refreshClick = shallowRef(false)
    const channels = shallowRef<DepositChannel[]>([])
    const ready = shallowRef(false)

    changeAccountType(AccountType.REAL)

    const refreshBalance = () => {
      refreshClick.value = true
      refreshAccount()
      setTimeout(() => refreshClick.value = false, 1000)
    }

    readChannelList()
      .then(resp => channels.value = resp)
      .finally(() => ready.value = true)

    return {
      ready,
      channels,
      refreshClick,
      refreshBalance,
    }
  },
})
</script>
