/**
* @author ayou[beica1@outook.com]
* @date 2022/3/11 11:41
* @description
*   TheRefreshIcon.vue of WeTrade
*/
<template>
<span @click="roll = true">
  <Icon name="refresh" :class="{roll}" @transitionend="roll = false" :sm="sm" />
  <slot />
</span>
</template>

<script lang="ts">
import Icon from '@/components/Icon.vue'
import { defineComponent, shallowRef } from 'vue'

export default defineComponent({
  name: 'TheRefreshIcon',
  components: { Icon },
  props: {
    sm: Boolean,
  },
  setup () {
    const roll = shallowRef(false)

    return {
      roll,
    }
  },
})
</script>

<style scoped lang="scss">
.roll {
  transform: rotate(360deg);
  transition: all .6s;
}
</style>
