
import Icon from '@/components/Icon.vue'
import { defineComponent, shallowRef } from 'vue'

export default defineComponent({
  name: 'TheRefreshIcon',
  components: { Icon },
  props: {
    sm: Boolean,
  },
  setup () {
    const roll = shallowRef(false)

    return {
      roll,
    }
  },
})
