
import { refreshAccount } from '@/modules/app/app'
import TheRefreshIcon from '@/modules/in/components/TheRefreshIcon.vue'
import { DepositChannel, readChannelList } from '@/modules/in/in.api'
import TheAsset from '@/modules/user/components/TheAsset.vue'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import { AccountType } from '@/state'
import { changeAccountType } from '@/state/accountType'
import Pic from 'common/Pic.vue'
import { defineComponent, shallowRef } from 'vue'

export default defineComponent({
  components: { TheRefreshIcon, Pic, TheAsset, PageWithHeader },
  setup () {
    const refreshClick = shallowRef(false)
    const channels = shallowRef<DepositChannel[]>([])
    const ready = shallowRef(false)

    changeAccountType(AccountType.REAL)

    const refreshBalance = () => {
      refreshClick.value = true
      refreshAccount()
      setTimeout(() => refreshClick.value = false, 1000)
    }

    readChannelList()
      .then(resp => channels.value = resp)
      .finally(() => ready.value = true)

    return {
      ready,
      channels,
      refreshClick,
      refreshBalance,
    }
  },
})
